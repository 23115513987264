<template>
  <v-sheet max-height="350" class="my-10">
    <v-row id="testimony" justify="center">
      <v-col v-if="!isMobile" cols="1" align-self="center">
        <v-btn @click="item--" :disabled="item <= 0" fab dark color="blue">
          <v-icon>mdi-arrow-left-bold</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10" md="8" align-self="start" align="center" id="paragraph">
        <v-carousel
          v-model="item"
          height="100%"
          :show-arrows="false"
          hide-delimiters
          cycle
          interval="8000"
        >
          <v-carousel-item v-for="t in testimonies" :key="t.first_name">
            <div align="start">
              <v-icon dark x-large>mdi-format-quote-open</v-icon>
            </div>
            <p v-if="testimonies" class="message">{{ t.message }}</p>
            <p v-if="testimonies" class="text-left white--text">
              {{ t.first_name }} | {{ t.country }}
            </p>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col v-if="!isMobile" cols="1" align-self="center">
        <v-btn
          v-if="testimonies"
          @click="item++"
          :disabled="item >= testimonies.length - 1"
          fab
          dark
          color="blue"
        >
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-center mt-3">
      Have a Testimony of your own?
      <v-btn color="blue" text dark x-large @click="goto">Leave Your Testimony</v-btn>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "HomePageTestimony",
  data: () => ({
    loadingTestimony: false,
    item: 0,
  }),

  computed: {
    testimonies() {
      return this.$store?.state?.testimonyObjects?.testimonies?.results;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    goto() {
      this.$emit("goto");
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$store.state.testimonyObjects.testimonies.results) {
        this.$store.dispatch("testimonyObjects/getTestimonies");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.message {
  text-align: left;
  color: white;
  text-shadow: 1px 4px 6px rgb(18, 96, 173), 0 0 0 rgb(0, 0, 0), 1px 4px 6px rgb(32, 125, 218);
  font-family: "Montserrat", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.45em;
  padding: 0px 30px 0px 30px;

  @media (max-width: 1024px) {
    padding: 0px 50px 0px 70px;

    @media (max-width: 767px) {
      font-size: 16px;
      width: 100%;
      padding: 0px 0px 0px 10px;
    }
  }
}
#testimony {
  background-image: url("../../assets/bluewater.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
#paragraph {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(35px);
  margin: 10px 0;
}
</style>
