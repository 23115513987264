<template>
  <div>
    <div class="text-h5 text-center"><u>Find us On Social Media</u></div>
    <v-row :justify="justifys" class="mt-8">
      <v-col align="center" align-self="center" cols="2" v-for="r in socialLinks" :key="r.name ? r.name : r.icon"
        v-show="r.show">
        <v-tooltip top color="blue lighten-1" dark>
          <template v-slot:activator="{ on, attrs }">
            <div v-if="r.route">
              <v-btn fab dark color="blue" :to="r.route" v-bind="attrs" v-on="on" elevation="15" v-ripple>
                <v-icon>{{ r.icon }}</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn fab dark color="blue" :href="r.href" v-bind="attrs" v-on="on" elevation="15" v-ripple>
                <v-icon large>{{ r.icon }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ r.name }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "HomePageFab",
  data: () => ({
    routes: [
      {
        name: "Speakers",
        route: "/featured-speakers",
        icon: "mdi-account-tie-voice",
      },
      {
        name: "Schedule",
        route: "/program-schedule",
        icon: "mdi-calendar-month",
      },
      {
        name: "Donate",
        route: "/donate",
        icon: "mdi-cash",
      },
      {
        name: "Links",
        route: "/how-to-listen",
        icon: "mdi-headphones",
      },
    ],
    socialLinks: [
      {
        show: true,
        name: 'Facebook',
        target: "_blank",
        href: "https://facebook.com/gracewayradio",
        icon: 'mdi-facebook'
      },
      {
        show: true,
        name: 'Twitter',
        target: "_blank",
        href: "https://twitter.com/gracewayradio",
        icon: 'mdi-twitter'
      },
      // {
      //   show: true,
      //   name: 'Pinterest',
      //   target: "_blank",
      //   href: "https://www.pinterest.com/GWRadio",
      //   icon: "mdi-pinterest"
      // },
      {
        show: true,
        name: 'Linkedin',
        target: '_blank',
        href: 'https://www.linkedin.com/in/graceway-radio-183049205/',
        icon: 'mdi-linkedin'
      },
      {
        show: true,
        name: 'Instagram',
        target: "_blank",
        href: "https://www.instagram.com/gracewayradio/",
        icon: "mdi-instagram"
      },
      // {
      //   show: false,
      //   name: 'Email',
      //   target: "_blank",
      //   href: "mailto:admin@gracewayradio.com",
      //   icon: 'mdi-email'
      // },
    ]
  }),
  computed: {
    justifys() {
      return this.$vuetify.breakpoint.mdAndDown ? "space-around" : "center";
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
};
</script>
