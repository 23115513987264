<template>
  <v-row justify="center" class="mb-6" id="vid">
    <v-col align-self="center" align="center">
      <p
        class="text-center"
        :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h3'"
        id="ptext"
      >Take Graceway Radio with you wherever you go!</p>
    </v-col>
    <v-col align-self="center" align="center">
      <video
        :height="$vuetify.breakpoint.smAndDown ? '150' : '300'"
        controls
        disablepictureinpicture
        disableremoteplayback
        x-webkit-airplay="deny"
        preload="auto"
      >
        <source v-for="(s, i) in sources" :key="i" :type="s.type" :src="s.src" />
        <p>Your Browser Does Not Support This Video Format.</p>
      </video>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "VideoSheet",
  data: () => ({
    sources: [
      {
        type: "video/quicktime",
        src: "https://gwrapiblogpics.s3.us-west-2.amazonaws.com/video/GracewayPromowCredits.mov",
      },
      {
        type: "video/webm",
        src: "https://d1cku70i8cl0rj.cloudfront.net/GracewayPromowCreditsWEBM.webm",
      },
      {
        type: "video/mp4",
        src: "https://d1cku70i8cl0rj.cloudfront.net/GracewayPromowCreditsMP4.mp4"
      }
      // {
      //   type: "video/x-msvideo",
      //   src: "https://gwrapiblogpics.s3.us-west-2.amazonaws.com/video/GracewayPromowCreditsAVI.avi",
      // },
    ],
  }),
};
</script>

<style lang="scss" scoped>
// @import url("https://fonts.googleapis.com/css?family=Kanit:900");

$LIGHTING_FLOOR: 1;
$LIGHTING_CEIL: 2;
$LIGHTING_FLAT: 3;

@mixin text3d(
  $primary,
  $depth: 5,
  $shadowsteps: 5,
  $shadowincrementer: 3px,
  $shadowopacity: 0.5,
  $primaryshadowcolour: #000,
  $lighting: $LIGHTING_CEIL
) {
  $predefinedShadows: (
    0 0 5px rgba($primaryshadowcolour, 0.05),
    0 -1px 3px rgba($primaryshadowcolour, 0.2),
    0 3px 5px rgba($primaryshadowcolour, 0.2)
  );
  $value: ();
  @for $i from 1 through $depth {
    $num: $i + px;
    $hueadjust: $i;
    @if ($lighting == $LIGHTING_FLOOR) {
      $hueadjust: ($i * 2 - $depth - 5) * 1%;
    } @else if ($lighting == $LIGHTING_CEIL) {
      $hueadjust: -($i * 2 + $depth - 10) * 1%;
    } @else if ($lighting == $LIGHTING_FLAT) {
      $hueadjust: -$depth * 1%;
    }
    $colour: adjust-color($primary, $lightness: $hueadjust);
    $theShadow: 0 $num 0 $colour;
    $value: append($value, $theShadow, comma);
  }

  @for $i from 1 through $shadowsteps {
    @if ($i >= length($predefinedShadows)) {
      $dist: $i * $shadowincrementer;
      $value: append(
        $value,
        0 $dist $dist rgba($primaryshadowcolour, $shadowopacity)
      );
    } @else {
      $value: append($value, nth($predefinedShadows, $i));
    }
  }

  text-shadow: $value;
}

$primarycolour: rgb(230, 190, 190);
#ptext {
  color: #fff;
  font-family: "Kanit" !important;
  font-size: 60px;
  line-height: 1em;

  @include text3d(
    adjust-color($primarycolour, $lightness: -8%),
    $depth: 8,
    $primaryshadowcolour:
      adjust-color($primarycolour, $lightness: -10%, $saturation: +20%),
    $shadowopacity: 0.3
  );
}
#vid {
  background: url("../../assets/radio-person.jpg"), rgb(13, 82, 138);
  background-position: center center;
  background-size: cover;
  background-blend-mode: lighten;
}
</style>
