<template>
  <v-card @click="
    $router.push({
      name: `${route}`
    })
  ">
    <v-hover v-slot:default="{ hover }">
      <v-img :src="image">
        <v-expand-transition>
          <div
            class="d-flex transition-fast-in-fast-out cyan lighten-2 v-card--reveal"
            :style="hover || $vuetify.breakpoint.smAndDown ? 'height: 38%' : 'height: 5%'"
          >
            <div
              v-if="hover || $vuetify.breakpoint.smAndDown"
              class="hover-text rounded transition-fast-in-fast-out"
              style="background-color: #0d47a1"
              :style="$vuetify.breakpoint.smAndDown ? 'font-size: 8vw' : 'font-size: 190%'"
            >{{ text }}</div>
          </div>
        </v-expand-transition>
      </v-img>
    </v-hover>
  </v-card>
</template>

<script>
export default {
  name: "HomePageTile",
  props: {
    route: {
      type: String
    },
    image: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>
