<template>
  <v-sheet class="py-10">
    <v-row justify="center">
      <v-col align-self="center" align="start" cols="12" md="6">
        <v-card elevation="0">
          <v-card-title class="justify-center text-h3 title blue--text">About Us</v-card-title>
          <v-card-text class="pr-0 font-weight-bold text-h6">
            GraceWay Radio is a one-of-a-kind station that is dedicated to the transmission of true
            worship and Spirit-inspired messages that will foster real spiritual growth in
            listeners
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "HomePageAbout"
};
</script>
