<template>
  <v-dialog :value="showVerse" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-card style="cursor: pointer" v-bind="attrs" v-on="on" @click="activate">
        <v-hover v-slot:default="{ hover }">
          <v-img src="~@/assets/verse.jpg" alt="Verse of the Day">
            <v-expand-transition>
              <div
                class="d-flex transition-fast-in-fast-out cyan lighten-2 v-card--reveal"
                :style="hover || $vuetify.breakpoint.smAndDown ? 'height: 38%' : 'height: 5%'"
              >
                <div
                  v-if="hover || $vuetify.breakpoint.smAndDown"
                  class="hover-text transition-fast-in-fast-out"
                  style="background-color: #0d47a1"
                  :style="$vuetify.breakpoint.smAndDown ? 'font-size: 10vw' : ''"
                >
                  <span v-if="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly">
                    Verse of the Day
                  </span>
                  <span v-else>Today's Verse</span>
                </div>
              </div>
            </v-expand-transition>
          </v-img>
        </v-hover>
      </v-card>
    </template>
    <router-view v-click-outside="close"></router-view>
  </v-dialog>
</template>

<script>
export default {
  name: 'TodaysVerse',
  computed: {
    showVerse() {
      return this.$route.meta && this.$route.meta.showModal;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    activate() {
      this.$emit('activate');
    },
  },
};
</script>
