<template>
  <v-carousel
    v-if="slides.length"
    v-model="model"
    width="1200"
    height="auto"
    :show-arrows="false"
    :hide-delimiters="$vuetify.breakpoint.smAndDown"
    cycle
    interval="8000"
    style="position: relative; margin-bottom:10px"
  >
    <v-carousel-item
      :style="(slide && slide.link) || (slide && slide.requested) ? 'cursor: pointer' : ''"
      @click="navigateTo(slide)"
      v-for="slide in slidesToUse"
      :key="slide.id"
      eager
    >
      <v-img :alt="slide.alt_text" :src="slide.image" height="100%" eager />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data: () => ({
    model: 0
  }),
  name: "Slides",
  computed: {
    slides() {
      return this.$store?.state?.slideObjects?.slides;
    },
    slidesWithoutPlayNow() {
      return this.slides.filter(slide => slide.play_now === false);
    },
    slidesToUse() {
      if (!this.audioIsPlaying) {
        return this.slides;
      }
      return this.slidesWithoutPlayNow;
    },
    audioIsPlaying() {
      return this.$store.state.audio.audioIsPlaying;
    }
  },
  methods: {
    navigateTo(slideObject) {
      if (slideObject?.link && slideObject?.requested) {
        this.$router.push({name: slideObject.link, params: {query: slideObject.requested}})
      } else if (slideObject?.link && !slideObject?.requested) {
        this.$router.push({ name: slideObject.link });
      } else if (
        slideObject?.requested &&
        typeof slideObject?.requested === "string" &&
        slideObject?.requested?.length >= 3
      ) {
        this.$router.push({ name: "Requests", params: { query: slideObject.requested } });
      } else if (slideObject?.play_now) {
        this.$store.dispatch("audio/audioPlay");
      }
    }
  }
};
</script>
